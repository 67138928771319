/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { DEFAULT_PAGINATION_PAGE } from 'common/config';

export interface ISupplyContext {
  page: number;
  setPage: (value: number) => void;
  openSupplyCard: boolean;
  setOpenSupplyCard: (value: boolean) => void;
  fetchLoading: boolean;
  setFetchLoading: (value: boolean) => void;
  openSupplyCorrectionRequestDrawer: boolean;
  setOpenSupplyCorrectionRequestDrawer: (value: boolean) => void;
}

export const SupplyContext = createContext<ISupplyContext>({
  page: DEFAULT_PAGINATION_PAGE,
  setPage: () => {},
  openSupplyCard: false,
  setOpenSupplyCard: () => {},
  fetchLoading: false,
  setFetchLoading: () => {},
  openSupplyCorrectionRequestDrawer: false,
  setOpenSupplyCorrectionRequestDrawer: () => {},
});

export const useSupplyContext = () => useContext(SupplyContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const SupplyProvider: FC<IComponentProps> = ({ children }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [openSupplyCard, setOpenSupplyCard] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [openSupplyCorrectionRequestDrawer, setOpenSupplyCorrectionRequestDrawer] = useState<boolean>(false);

  return (
    <SupplyContext.Provider
      value={{
        page,
        setPage,
        openSupplyCard,
        setOpenSupplyCard,
        fetchLoading,
        setFetchLoading,
        openSupplyCorrectionRequestDrawer,
        setOpenSupplyCorrectionRequestDrawer,
      }}
    >
      {children}
    </SupplyContext.Provider>
  );
};
