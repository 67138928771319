import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Input, Select, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { ENeedCardMenuItem, ENeedStatus } from 'common/const/enum';
import {
  COLLECTION_LIMIT_5,
  DEFAULT_EMPTY_VALUE,
  DEFAULT_LIST_LIMIT,
  DEFAULT_LIST_OFFSET,
  DEFAULT_PAGINATION_PAGE,
} from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ExportIcon } from 'app/assets/images/redesign/export.svg';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import {
  getNeedListSubdivisionOptions,
  getNeedListUserOptions,
  renderPendingNeedListForManagerRecords,
} from 'entities/Need/Need.helper';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { SupplyCorrectionRequestDrawer } from 'entities/Supply/components/SupplyCorrectionRequestDrawer';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PendingNeedListForManagerComponent: React.FC<AllType> = (props) => {
  const {
    // state
    subdivisionList,
    userList,
    needList,
    needListLoading,
    needListCount,
    //   // dispatch
    filterNeedList,
    getNeedSubdivisionListForManager,
    getNeedUserList,
    getNeedList,
    uploadNeedExcel,
    getNeedListPart,
    changeNeedStatus,
    getNeedById,
  } = props;

  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: [ENeedStatus.Pending],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const {
    page,
    setPage,
    setNeedCardCurrentMenuTab,
    setOpenNeedCard,
    fetchLoading,
    setFetchLoading,
    selectedNeedList,
    setSelectedNeedList,
  } = useNeedContext();
  const { setOpenSupplyCard } = useSupplyContext();

  const selectedNeedListIds = selectedNeedList.map((need) => need.id);
  const checkAll = needList.length === selectedNeedList.length;
  const indeterminate = selectedNeedList.length > 0 && selectedNeedList.length < needList.length;
  const subdivisionOptions = getNeedListSubdivisionOptions(subdivisionList);
  const userOptions = getNeedListUserOptions(userList);

  const onSubdivisionChange = async (value: number) => {
    const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value], userId: undefined };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    await getNeedList(newPayload);
    await getNeedUserList({ statuses: payload.statuses, subdivisionId: value === DEFAULT_EMPTY_VALUE ? undefined : value });
  };

  const onUserChange = async (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    await getNeedList(newPayload);
  };

  const onSearchClick = () => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    getNeedList(payload);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedNeedList(needList);
    } else {
      setSelectedNeedList([]);
    }
  };

  const onNeedSelectionChange = (id: number, checked: boolean) => {
    const need = needList.find((item) => item.id === id) as INeed;

    setSelectedNeedList(checked ? [...selectedNeedList, need] : selectedNeedList.filter((need) => need.id !== id));
  };

  const onRejectClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Cancelled,
      onSuccess: () => {
        filterNeedList(selectedNeedListIds);
        setSelectedNeedList([]);
        setOpenWarningDrawer(false);
        showSuccessMessage(`Заявки (${selectedNeedListIds.length}) завершены.`);

        if (selectedNeedListIds.length === needList.length && needListCount > selectedNeedListIds.length) {
          const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

          setPage(DEFAULT_PAGINATION_PAGE);
          setPayload(newPayload);
          getNeedList(newPayload);
        }
      },
    });
  };

  const onNeedCardSuccess = (id: number) => {
    filterNeedList([id]);
    setSelectedNeedList(selectedNeedList.filter((need) => need.id !== id));

    if (needList.length === COLLECTION_LIMIT_5 && needListCount > needList.length - COLLECTION_LIMIT_5) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getNeedList(newPayload);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedNeedList([]);
      await getNeedSubdivisionListForManager({ statuses: payload.statuses });
      await getNeedUserList({ statuses: payload.statuses });
      await getNeedList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={onSubdivisionChange}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={onUserChange}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />

        <Button className="button-circle secondary" icon={<ExportIcon />} onClick={() => uploadNeedExcel(payload)} />
      </div>

      {!!needList.length && (
        <div className="need-list__actions">
          <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
            Выбрать заявки
          </Checkbox>

          {!!selectedNeedList.length && (
            <Button className="button-s danger" onClick={() => setOpenWarningDrawer(true)}>
              Завершить выбранные
            </Button>
          )}
        </div>
      )}

      <InfiniteScrollContainer
        loading={fetchLoading || needListLoading}
        canLoad={!needListLoading && needList.length < needListCount}
        page={page}
        scrollToTopTrigger={[payload]}
        onPageChange={setPage}
        callback={async (page) => {
          await getNeedListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
        }}
      >
        <Table
          className={`table-hover need-list__table ${
            !!needList.length && needList.length === needListCount && 'need-list__table-end-of-list'
          }`}
          dataSource={needList.map((need) => ({ ...need, key: need.id }))}
          columns={renderPendingNeedListForManagerRecords()}
          rowSelection={{
            selectedRowKeys: selectedNeedListIds,
            onSelect: ({ id }, checked) => onNeedSelectionChange(id, checked),
            columnWidth: 20,
          }}
          onRow={({ id }) => ({
            onClick: () => {
              getNeedById({
                id,
                onSuccess: () => {
                  setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                  setOpenNeedCard(true);
                },
              });
            },
          })}
          showHeader={false}
          pagination={false}
          locale={{ emptyText: 'Список пуст.' }}
        />
      </InfiniteScrollContainer>

      <NeedCard onClose={() => setOpenNeedCard(false)} onSuccess={onNeedCardSuccess} />

      <SupplyCard
        isNested
        onClose={() => {
          setOpenSupplyCard(false);
          setOpenNeedCard(true);
        }}
      />

      <SupplyCorrectionRequestDrawer />

      <WarningDrawer
        open={openWarningDrawer}
        content={`Вы уверены, что хотите завершить выбранные заявки (${selectedNeedList.length})?`}
        subtitle="Запланированные поставки по данным заявкам будут отменены."
        confirmBtnTitle="Завершить все"
        loading={needListLoading}
        onClose={() => setOpenWarningDrawer(false)}
        onConfirm={onRejectClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.needListState.subdivisionList,
  userList: state.needListState.userList,
  needList: state.needListState.data,
  needListLoading: state.needListState.loading,
  needListCount: state.needListState.count,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  filterNeedList: dispatch.needListState.filterNeedList,
  getNeedSubdivisionListForManager: dispatch.needListState.getNeedSubdivisionListForManager,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedList: dispatch.needListState.getNeedList,
  uploadNeedExcel: dispatch.needListState.uploadNeedExcel,
  getNeedListPart: dispatch.needListState.getNeedListPart,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  getNeedById: dispatch.needState.getNeedById,
});

export const PendingNeedListForManager = connect(mapState, mapDispatch)(PendingNeedListForManagerComponent);
