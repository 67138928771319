import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { EDrawerZIndex } from 'common/const/enum';

interface IComponentProps {
  open: boolean;
  content: string;
  subtitle: string;
  confirmBtnTitle: string;
  loading: boolean;
  onClose?: () => void;
  onConfirm: () => void;
}

export const WarningDrawer: FC<IComponentProps> = ({ open, subtitle, content, confirmBtnTitle, loading, onClose, onConfirm }) => {
  return (
    <Drawer
      rootClassName="redesign warning-drawer"
      open={open}
      onClose={loading ? undefined : onClose}
      width={720}
      zIndex={EDrawerZIndex.WarningDrawer}
    >
      <div className="warning-drawer__container">
        <span className="text-h1 warning-drawer__content">{content}</span>

        <span className="text-h3 color-dark-grey">{subtitle}</span>
      </div>

      <div className="warning-drawer__footer">
        <Button className="button-l danger" onClick={onConfirm} loading={loading}>
          {confirmBtnTitle}
        </Button>

        <Button className="button-l secondary" onClick={onClose} disabled={loading}>
          Отмена
        </Button>
      </div>
    </Drawer>
  );
};
