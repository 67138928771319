import React, { FC, useEffect, useRef, useState } from 'react';
import { Select, Table } from 'antd';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERoute, ESearchParams } from 'common/const/enum';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { DEFAULT_FULL_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, GOODS_LIST_LIMIT } from 'common/config';
import { useSearchParamsHook } from 'common/hooks/useSearchParamsHook';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { CatalogBreadcrumb } from 'entities/Catalog/components/CatalogBreadcrumb';
import { CatalogCategoryList } from 'entities/Catalog/components/CatalogCategoryList';
import { CatalogEmpty } from 'entities/Catalog/components/CatalogEmpty';
import { renderCatalogGoodsListRecords, getCatalogEmptyStateContent } from 'entities/Catalog/Catalog.helper';
import { catalogGoodsAvailabilityFilterOptions } from 'entities/Catalog/Catalog.const';
import { IGoodsListPayload } from 'entities/Goods/Goods.models';

interface IComponentProps {
  catalogId?: number;
  catalogName?: string;
  catalogListLoading: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CatalogSearchPageComponent: FC<AllType> = (props) => {
  const {
    // props
    catalogId,
    catalogName,
    catalogListLoading,
    // state
    categoryList,
    goodsList,
    goodsListLoading,
    goodsListCount,
    goodsLoading,
    // dispatch
    setCategoryList,
    getCategoryList,
    setGoodsList,
    getGoodsList,
    getGoodsListPart,
  } = props;

  const [goodsListPayload, setGoodsListPayload] = useState<IGoodsListPayload | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const goodsListFilterChipsRef = useRef<HTMLDivElement | null>(null);
  const { getSearchParam } = useSearchParamsHook();
  const { page, scrollUp, fetchLoading, setPage, setScrollDirection, setFetchLoading, onOpenGoodsCard } = useCatalogContext();

  const search = getSearchParam(ESearchParams.Search);
  const searchNotEmpty = search !== null;
  const categoryListLoading = catalogListLoading || fetchLoading;
  const showEmptyState = !categoryListLoading && !goodsListLoading && !goodsList.length && !!goodsListPayload;
  const goodsListFilterChipsHeight = goodsListFilterChipsRef.current?.clientHeight || 0;
  const goodsListColumns = renderCatalogGoodsListRecords({ searchNotEmpty });
  const { emptyTitle, emptyDescription, emptyBackBtnTitle } = getCatalogEmptyStateContent({
    payload: goodsListPayload,
    searchNotEmpty,
  });

  const onGoodsListAvailabilityFilterChange = (value: boolean) => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setGoodsListPayload((prev) => ({ ...prev, isAvailableForPurchase: value ? value : undefined }));
  };

  const onCatalogEmptyPageBackClick = () => {
    if (catalogId) {
      navigate(`${ERoute.Catalog}/${catalogId}`);
    }
  };

  useEffect(() => {
    if (goodsListPayload) {
      getGoodsList(goodsListPayload);
    }
  }, [goodsListPayload]);

  useEffect(() => {
    const fetch = async () => {
      setGoodsList([]);
      setPage(DEFAULT_PAGINATION_PAGE);
      setFetchLoading(true);

      if (catalogId && search !== null) {
        await getCategoryList({ catalogId, search, limit: DEFAULT_FULL_LIST_LIMIT });
        setGoodsListPayload((prev) => ({
          ...prev,
          search,
          categoryId: undefined,
          isAvailableForPurchase: undefined,
          offset: DEFAULT_LIST_OFFSET,
          limit: GOODS_LIST_LIMIT,
        }));
      }

      setFetchLoading(false);
    };

    fetch();
  }, [location, catalogId]);

  useEffect(() => {
    return () => {
      setFetchLoading(true);
      setCategoryList([]);
      setGoodsList([]);
    };
  }, []);

  return (
    <InfiniteScrollContainer
      canLoad={!goodsListLoading && goodsList.length < goodsListCount}
      page={page}
      containerClassName="catalog__scroll-container"
      scrollToTopTrigger={[categoryList, goodsListPayload]}
      onPageChange={setPage}
      setScrollDirection={setScrollDirection}
      callback={async (page) => {
        if (goodsListPayload) {
          await getGoodsListPart({ ...goodsListPayload, offset: GOODS_LIST_LIMIT * page });
        }
      }}
    >
      <CatalogBreadcrumb catalogId={catalogId} catalogName={catalogName} className="catalog__breadcrumb" />

      <span className="text-h1 catalog__title">{`Результаты для “${search}”`}</span>

      <CatalogCategoryList loading={fetchLoading} collapsible={true} categoryList={categoryList} />

      <div
        className="catalog__goods-list-filter-chips"
        style={{ top: scrollUp ? 0 : -goodsListFilterChipsHeight }}
        ref={goodsListFilterChipsRef}
      >
        <Select
          rootClassName="redesign"
          value={!!goodsListPayload?.isAvailableForPurchase}
          onChange={onGoodsListAvailabilityFilterChange}
          options={catalogGoodsAvailabilityFilterOptions}
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />
      </div>

      <Table
        className={`
          table-hover
          catalog__goods-list
          ${!!goodsList.length && 'catalog__goods-list-full-screen'}
          ${!!goodsList.length && goodsList.length === goodsListCount && 'catalog__goods-list-end-of-list'}
          ${!goodsList.length && 'catalog__goods-list-empty'}
        `}
        dataSource={goodsList.map((goods) => ({ key: goods.id, ...goods }))}
        columns={goodsListColumns}
        pagination={false}
        loading={{ spinning: fetchLoading || goodsListLoading || goodsLoading, indicator: <SpinIndicator /> }}
        showHeader={!!goodsList.length}
        onRow={({ id, goodInBasket }) => ({ onClick: () => onOpenGoodsCard(id, goodInBasket) })}
        onHeaderRow={() => ({
          className: 'catalog__goods-list-header',
          style: { top: scrollUp ? goodsListFilterChipsHeight : 0 },
        })}
        locale={{
          emptyText: (
            <CatalogEmpty
              open={showEmptyState}
              title={emptyTitle}
              description={emptyDescription}
              btnTitle={emptyBackBtnTitle}
              onBack={onCatalogEmptyPageBackClick}
            />
          ),
        }}
      />
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  categoryList: state.categoryListState.data,
  goodsList: state.goodsListState.data,
  goodsListLoading: state.goodsListState.loading,
  goodsListCount: state.goodsListState.count,
  goodsLoading: state.goodsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setCategoryList: dispatch.categoryListState.setCategoryList,
  getCategoryList: dispatch.categoryListState.getCategoryList,
  setGoodsList: dispatch.goodsListState.setGoodsList,
  getGoodsList: dispatch.goodsListState.getGoodsList,
  getGoodsListPart: dispatch.goodsListState.getGoodsListPart,
});

export const CatalogSearchPage = connect(mapState, mapDispatch)(CatalogSearchPageComponent);
