import React, { FC, useState } from 'react';
import { Button, Drawer, Spin } from 'antd';
import { connect } from 'react-redux';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { NameLabel } from 'common/components/NameLabel';
import { NeedStatus } from 'common/components/NeedStatus';
import { SupplyStatus } from 'common/components/SupplyStatus';
import { Image } from 'common/components/Image';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { EDrawerZIndex, ENeedCardMenuItem, ESupplyStatus, EUserRole } from 'common/const/enum';
import { countFormatter, dateFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { getUserInitials, getUserName } from 'entities/Users/Users.helper';

interface IComponentProps {
  isNested?: boolean;
  onClose: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const SupplyCardComponent: FC<AllType> = (props) => {
  const {
    // props
    isNested,
    onClose,
    // state
    supplyListLoading,
    auth,
    supply,
    need,
    needLoading,
    // dispatch
    changeSupplyStatus,
    updateNeedListItemPendingSuppliesCount,
  } = props;

  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const { openSupplyCard, setOpenSupplyCard, setOpenSupplyCorrectionRequestDrawer } = useSupplyContext();
  const { setNeedCardCurrentMenuTab, setOpenNeedCard } = useNeedContext();

  const isManager = auth?.access.roles.includes(EUserRole.Manager);
  const count = supply ? supply.count : 0;
  const amount = supply ? supply.price * count : 0;
  const amountWithTaxes = supply ? supply.priceWithTaxes * count : 0;

  const onPlaceClick = () => {
    if (supply) {
      changeSupplyStatus({
        ids: [supply.id],
        status: ESupplyStatus.Approved,
        onSuccess: () => {
          setOpenSupplyCard(false);
          setOpenSuccessDrawer(true);

          if (need) {
            updateNeedListItemPendingSuppliesCount({ id: need.id, count: need.pendingSuppliesCount - 1 });
          }
        },
      });
    }
  };

  if (!supply) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName={`redesign supply-card ${isNested ? 'nested' : ''}`}
        open={openSupplyCard}
        onClose={supplyListLoading ? undefined : onClose}
        width={800}
        closeIcon={isNested ? <ArrowLeftLongIcon /> : <CloseIcon />}
        zIndex={EDrawerZIndex.SupplyCard}
      >
        <div className="supply-card__content">
          <span className="text-h1-drawers supply-card__title">{`Поставка #${supply.id}`}</span>

          <div style={{ marginBottom: 52 }}>
            <SupplyStatus status={supply.status} />
          </div>

          <div className="supply-card__block">
            <span className="text-h4 supply-card__block-title">Информация о поставке</span>

            <div className="supply-card__goods">
              <Image size={80} src={supply.good.image} />

              <div style={{ flex: 1 }}>
                <div className="text-h4 supply-card__goods-name" title={supply.good.name}>
                  {supply.good.name}
                </div>

                <div className="supply-card__goods-container">
                  <span className="text-accent">{priceFormatter(supply.price)}</span>

                  {supply.good.sellerCode && <span className="text-tag color-dark-grey">{supply.good.sellerCode}</span>}
                </div>
              </div>
            </div>

            <div className="supply-card__block-table">
              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Количество:</span>

                <span className="text-body">{countFormatter(count)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Стоимость товаров:</span>

                <span className="text-body">{priceFormatter(amount)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Сумма НДС:</span>

                <span className="text-body">{priceFormatter(amountWithTaxes - amount)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Стоимость с НДС:</span>

                <span className="text-body">{priceFormatter(amountWithTaxes)}</span>
              </div>

              <div className="supply-card__block-table-row">
                <span className="text-body color-dark-grey">Поставщик:</span>

                <NameLabel icon={<DeliveryIcon />} name={supply.sellerName} />
              </div>
            </div>
          </div>

          <div className="supply-card__block">
            <span className="text-h4 supply-card__block-title">Детали доставки</span>

            <div className="supply-card__block-table">
              {supply.address && (
                <div className="supply-card__block-table-row">
                  <span className="text-body color-dark-grey">Адрес доставки:</span>

                  <span className="text-body">{supply.address.name}</span>
                </div>
              )}

              {supply.deliveryDate && (
                <div className="supply-card__block-table-row">
                  <span className="text-body color-dark-grey">Срок доставки:</span>

                  <span className="text-body">{dateFormatter.fullDateDotSeparator(supply.deliveryDate)}</span>
                </div>
              )}
            </div>
          </div>

          <Spin spinning={needLoading} indicator={<SpinIndicator />}>
            {need && (
              <div
                className="supply-card__need"
                onClick={() => {
                  setOpenSupplyCard(false);
                  setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                  setOpenNeedCard(true);
                }}
              >
                <div className="supply-card__need-header">
                  <span>
                    <span className="text-lists color-dark-grey">Заявка</span>

                    <span className="text-lists">{' #'}</span>

                    <span className="text-accent">{need.id}</span>
                  </span>

                  <NeedStatus status={need.status} />
                </div>

                <div className="supply-card__need-divider" />

                <div className="supply-card__need-block">
                  <Image size={80} src={need.good.image} />

                  <div className="supply-card__need-block-table">
                    <div className="supply-card__need-block-table-row">
                      <span className="text-body color-dark-grey">Заявка от:</span>

                      <span className="text-body">{dateFormatter.fullDate(need.createdAt)}</span>
                    </div>

                    <div className="supply-card__need-block-table-row">
                      <span className="text-body color-dark-grey">Юридическое лицо:</span>

                      <span className="text-body">{need.legalName}</span>
                    </div>

                    <div className="supply-card__need-block-table-row">
                      <span className="text-body color-dark-grey">Заявитель:</span>

                      <span className="supply-card__need-user">
                        <span className="text-profile-icon color-dark-grey supply-card__need-user-initials">
                          {getUserInitials(need.user.firstName, need.user.lastName)}
                        </span>

                        <span className="text-h4-item-name color-dark-grey supply-card__need-user-name">
                          {getUserName(need.user.firstName, need.user.lastName)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </div>

        <div className="supply-card__footer">
          {isManager && supply.status === ESupplyStatus.Pending && (
            <>
              <Button className="button-l primary" onClick={onPlaceClick} loading={supplyListLoading}>
                Разместить заказ
              </Button>

              <Button
                className="button-l secondary"
                onClick={() => {
                  setOpenSupplyCard(false);
                  setOpenSupplyCorrectionRequestDrawer(true);
                }}
                loading={supplyListLoading}
              >
                Запросить корректировку
              </Button>
            </>
          )}
        </div>
      </Drawer>

      <SuccessDrawer
        open={openSuccessDrawer}
        subtitle="Готово!"
        content="Заказ размещен у поставщика. Статусы запланированных поставок можно отследить в разделе “Поставки”."
        btnTitle="Назад к заявке"
        onClose={() => setOpenSuccessDrawer(false)}
        onConfirm={() => setOpenSuccessDrawer(false)}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  supplyListLoading: state.supplyListState.loading,
  auth: state.authState.data,
  supply: state.supplyState.data,
  need: state.needState.data,
  needLoading: state.needState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeSupplyStatus: dispatch.supplyListState.changeSupplyStatus,
  updateNeedListItemPendingSuppliesCount: dispatch.needListState.updateNeedListItemPendingSuppliesCount,
});

export const SupplyCard = connect(mapState, mapDispatch)(SupplyCardComponent);
