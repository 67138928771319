import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Input, Select, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { ENeedCardMenuItem, ENeedStatus, ERoute } from 'common/const/enum';
import {
  COLLECTION_LIMIT_5,
  DEFAULT_EMPTY_VALUE,
  DEFAULT_LIST_LIMIT,
  DEFAULT_LIST_OFFSET,
  DEFAULT_PAGINATION_PAGE,
} from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ExportIcon } from 'app/assets/images/redesign/export.svg';
import { INeed, INeedListPayload } from 'entities/Need/Need.models';
import {
  getNeedListSubdivisionOptions,
  getNeedListUserOptions,
  getSelectedNeedListTotalAmount,
  renderCreatedNeedListForManagerRecords,
} from 'entities/Need/Need.helper';
import { NeedCard } from 'entities/Need/components/NeedCard';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CreatedNeedListForManagerComponent: React.FC<AllType> = (props) => {
  const {
    // state
    subdivisionList,
    userList,
    needList,
    needListLoading,
    needListCount,
    statisticsRequestsCount = 0,
    // dispatch
    filterNeedList,
    getNeedSubdivisionListForManager,
    getNeedUserList,
    getNeedList,
    uploadNeedExcel,
    getNeedListPart,
    getNeedById,
    updateStatisticsRequestsCount,
    changeNeedStatus,
  } = props;

  const [selectedNeedListCount, setSelectedNeedListCount] = useState<number>(0);
  const [selectedNeedListTotalAmount, setSelectedNeedListTotalAmount] = useState<number>(0);
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const [payload, setPayload] = useState<INeedListPayload>({
    statuses: [ENeedStatus.Created],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const navigate = useNavigate();
  const {
    page,
    setPage,
    setNeedCardCurrentMenuTab,
    setOpenNeedCard,
    fetchLoading,
    setFetchLoading,
    selectedNeedList,
    setSelectedNeedList,
  } = useNeedContext();

  const selectedNeedListIds = selectedNeedList.map((need) => need.id);
  const checkAll = needList.length === selectedNeedList.length;
  const indeterminate = selectedNeedList.length > 0 && selectedNeedList.length < needList.length;
  const subdivisionOptions = getNeedListSubdivisionOptions(subdivisionList);
  const userOptions = getNeedListUserOptions(userList);

  const onSubdivisionChange = async (value: number) => {
    const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value], userId: undefined };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    await getNeedList(newPayload);
    await getNeedUserList({ statuses: payload.statuses, subdivisionId: value === DEFAULT_EMPTY_VALUE ? undefined : value });
  };

  const onUserChange = async (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    await getNeedList(newPayload);
  };

  const onSearchClick = () => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setSelectedNeedList([]);
    getNeedList(payload);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const newSelectedNeedListTotalAmount = getSelectedNeedListTotalAmount(needList);

      setSelectedNeedList(needList);
      setSelectedNeedListCount(needList.length);
      setSelectedNeedListTotalAmount(newSelectedNeedListTotalAmount);
    } else {
      setSelectedNeedList([]);
    }
  };

  const onNeedSelectionChange = (id: number, checked: boolean) => {
    const need = needList.find((item) => item.id === id) as INeed;
    const newSelectedNeedList = checked ? [...selectedNeedList, need] : selectedNeedList.filter((need) => need.id !== id);
    const newSelectedNeedListTotalAmount = getSelectedNeedListTotalAmount(newSelectedNeedList);

    setSelectedNeedList(newSelectedNeedList);
    setSelectedNeedListCount(newSelectedNeedList.length);
    setSelectedNeedListTotalAmount(newSelectedNeedListTotalAmount);
  };

  const onSelectedNeedListSuccess = () => {
    filterNeedList(selectedNeedListIds);
    setSelectedNeedList([]);
    updateStatisticsRequestsCount(statisticsRequestsCount - selectedNeedListIds.length);

    if (selectedNeedListIds.length === needList.length && needListCount > selectedNeedListIds.length) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getNeedList(newPayload);
    }
  };

  const onAcceptToWorkClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Pending,
      onSuccess: () => {
        setOpenSuccessDrawer(true);
        onSelectedNeedListSuccess();
      },
    });
  };

  const onRejectClick = () => {
    changeNeedStatus({
      ids: selectedNeedListIds,
      status: ENeedStatus.Cancelled,
      onSuccess: () => {
        setOpenWarningDrawer(false);
        showSuccessMessage(`Заявки (${selectedNeedListIds.length}) отменены.`);
        onSelectedNeedListSuccess();
      },
    });
  };

  const onNeedCardSuccess = (id: number) => {
    const newSelectedNeedList = selectedNeedList.filter((need) => need.id !== id);
    const newSelectedNeedListTotalAmount = getSelectedNeedListTotalAmount(newSelectedNeedList);

    filterNeedList([id]);
    setSelectedNeedList(newSelectedNeedList);
    setSelectedNeedListCount(needList.length);
    setSelectedNeedListTotalAmount(newSelectedNeedListTotalAmount);
    updateStatisticsRequestsCount(statisticsRequestsCount - 1);

    if (needList.length === COLLECTION_LIMIT_5 && needListCount > needList.length - COLLECTION_LIMIT_5) {
      const newPayload = { ...payload, offset: DEFAULT_LIST_OFFSET };

      setPage(DEFAULT_PAGINATION_PAGE);
      setPayload(newPayload);
      getNeedList(newPayload);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      setPage(DEFAULT_PAGINATION_PAGE);
      setSelectedNeedList([]);
      await getNeedSubdivisionListForManager({ statuses: payload.statuses });
      await getNeedUserList({ statuses: payload.statuses });
      await getNeedList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <div className="need-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={onSubdivisionChange}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="need-list__filters-select-popup"
          className="need-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={onUserChange}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />

        <Button className="button-circle secondary" icon={<ExportIcon />} onClick={() => uploadNeedExcel(payload)} />
      </div>

      {!!needList.length && (
        <div className="need-list__actions">
          <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onCheckAllChange}>
            Выбрать заявки
          </Checkbox>

          {!!selectedNeedList.length && (
            <>
              <Button className="button-s primary" onClick={onAcceptToWorkClick}>
                {`Взять в работу: ${priceFormatter(selectedNeedListTotalAmount)}`}
              </Button>

              <Button className="button-s danger" onClick={() => setOpenWarningDrawer(true)}>
                Отклонить выбранные
              </Button>
            </>
          )}
        </div>
      )}

      <InfiniteScrollContainer
        loading={fetchLoading || needListLoading}
        canLoad={!needListLoading && needList.length < needListCount}
        page={page}
        scrollToTopTrigger={[payload]}
        onPageChange={setPage}
        callback={async (page) => {
          await getNeedListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
        }}
      >
        <Table
          className={`table-hover need-list__table ${
            !!needList.length && needList.length === needListCount && 'need-list__table-end-of-list'
          }`}
          dataSource={needList.map((need) => ({ ...need, key: need.id }))}
          columns={renderCreatedNeedListForManagerRecords()}
          rowSelection={{
            selectedRowKeys: selectedNeedListIds,
            onSelect: ({ id }, checked) => onNeedSelectionChange(id, checked),
            columnWidth: 20,
          }}
          onRow={({ id }) => ({
            onClick: () => {
              getNeedById({
                id,
                onSuccess: () => {
                  setNeedCardCurrentMenuTab(ENeedCardMenuItem.Info);
                  setOpenNeedCard(true);
                },
              });
            },
          })}
          showHeader={false}
          pagination={false}
          locale={{ emptyText: 'Список пуст.' }}
        />
      </InfiniteScrollContainer>

      <NeedCard onClose={() => setOpenNeedCard(false)} onSuccess={onNeedCardSuccess} />

      <SuccessDrawer
        open={openSuccessDrawer}
        subtitle="Готово!"
        content={`Вы взяли в работу ${selectedNeedListCount} новых заявок на общую сумму ${priceFormatter(
          selectedNeedListTotalAmount,
        )}`}
        btnTitle="К заявкам в работе"
        onClose={() => setOpenSuccessDrawer(false)}
        onConfirm={() => navigate(ERoute.PendingNeedListForManager)}
      />

      <WarningDrawer
        open={openWarningDrawer}
        content={`Вы уверены, что хотите завершить выбранные заявки (${selectedNeedList.length})?`}
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Завершить все"
        loading={needListLoading}
        onClose={() => setOpenWarningDrawer(false)}
        onConfirm={onRejectClick}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.needListState.subdivisionList,
  userList: state.needListState.userList,
  needList: state.needListState.data,
  needListLoading: state.needListState.loading,
  needListCount: state.needListState.count,
  statisticsRequestsCount: state.statisticsState.data.requestsCount,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  filterNeedList: dispatch.needListState.filterNeedList,
  getNeedSubdivisionListForManager: dispatch.needListState.getNeedSubdivisionListForManager,
  getNeedUserList: dispatch.needListState.getNeedUserList,
  getNeedList: dispatch.needListState.getNeedList,
  uploadNeedExcel: dispatch.needListState.uploadNeedExcel,
  getNeedListPart: dispatch.needListState.getNeedListPart,
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  getNeedById: dispatch.needState.getNeedById,
  updateStatisticsRequestsCount: dispatch.statisticsState.updateStatisticsRequestsCount,
});

export const CreatedNeedListForManager = connect(mapState, mapDispatch)(CreatedNeedListForManagerComponent);
