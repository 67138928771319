import React, { FC, useEffect, useState } from 'react';
import { Button, Drawer, Menu } from 'antd';
import { connect } from 'react-redux';
import { NeedStatus } from 'common/components/NeedStatus';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { useDebounce } from 'common/hooks/useDebounce';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { dateFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { ENeedCardMenuItem, ENeedStatus, EUserRole } from 'common/const/enum';
import { COUNT_CHANGE_DELAY, DEFAULT_FULL_LIST_LIMIT, DEFAULT_GOODS_COUNT } from 'common/config';
import { ReactComponent as ArrowLeftLongIcon } from 'app/assets/images/redesign/arrow-left-long.svg';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { RootDispatch, RootState } from 'app/store';
import { NeedCardInfo } from 'entities/Need/components/NeedCardInfo';
import { NeedCardSupplies } from 'entities/Need/components/NeedCardSupplies';
import { needCardMenuItems } from 'entities/Need/Need.const';
import { getNeedCardFormattedDate } from 'entities/Need/Need.helper';

interface IComponentProps {
  isNested?: boolean;
  onClose: () => void;
  onSuccess?: (id: number) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const NeedCardComponent: FC<AllType> = (props) => {
  const {
    isNested,
    onClose,
    onSuccess,
    // state
    needListLoading,
    auth,
    need,
    needLoading,
    // dispatch
    changeNeedStatus,
    getNeedSupplyList,
    getNeedHistoryList,
    updateNeed,
    getSupplyById,
  } = props;

  const [count, setCount] = useState<number>(DEFAULT_GOODS_COUNT);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const { needCardCurrentMenuTab, setNeedCardCurrentMenuTab, openNeedCard, setOpenNeedCard } = useNeedContext();
  const { setOpenSupplyCard } = useSupplyContext();

  const isManager = auth ? auth.access.roles.includes(EUserRole.Manager) : false;
  const needId = need?.id;

  const onCountChange = (value: number) => {
    setCountIsChanged(true);
    setCount(value);
  };

  const onAcceptToWorkClick = () => {
    if (needId) {
      changeNeedStatus({
        ids: [needId],
        status: ENeedStatus.Pending,
        onSuccess: () => {
          setOpenNeedCard(false);
          setOpenSuccessDrawer(true);
          onSuccess?.(needId);
        },
      });
    }
  };

  const onRejectClick = () => {
    setOpenNeedCard(false);
    setOpenWarningDrawer(true);
  };

  const handleReject = () => {
    if (needId) {
      changeNeedStatus({
        ids: [needId],
        status: ENeedStatus.Cancelled,
        onSuccess: () => {
          setOpenWarningDrawer(false);
          showSuccessMessage(`Заявка от ${dateFormatter.fullDateDotSeparator(need.createdAt)} завершена.`);
          onSuccess?.(needId);
        },
      });
    }
  };

  const onSupplyClick = (id: number) => {
    getSupplyById({
      id,
      onSuccess: () => {
        setOpenNeedCard(false);
        setOpenSupplyCard(true);
      },
    });
  };

  useEffect(() => {
    const fetch = async () => {
      if (openNeedCard && needId) {
        await getNeedSupplyList({ supplyNeedId: needId, orderDirection: 'ASC' });
        await getNeedHistoryList({ supplyNeedId: needId, limit: DEFAULT_FULL_LIST_LIMIT });
      }
    };

    fetch();
  }, [openNeedCard, needId]);

  useEffect(() => {
    if (need) {
      setCount(need.count);
    }
  }, [need]);

  useDebounce(
    () => {
      if (need && countIsChanged) {
        updateNeed({
          supplyNeeds: [{ id: need.id, count }],
          onSuccess: () => {
            setCountIsChanged(false);
            showSuccessMessage(`Количество товаров в заявке от ${dateFormatter.fullDateDotSeparator(need.createdAt)} изменено.`);
          },
        });
      }
    },
    [need, count, countIsChanged],
    COUNT_CHANGE_DELAY,
  );

  if (!need) {
    return null;
  }

  return (
    <>
      <Drawer
        rootClassName={`redesign need-card ${isNested ? 'nested' : ''}`}
        open={openNeedCard}
        onClose={needListLoading ? undefined : onClose}
        width={800}
        closeIcon={isNested ? <ArrowLeftLongIcon /> : <CloseIcon />}
      >
        <span className="text-body color-dark-grey need-card__created-at">{getNeedCardFormattedDate(need.createdAt)}</span>

        <span className="text-h1-drawers need-card__title">Заявка на закупку</span>

        <div style={{ marginBottom: 52 }}>
          <NeedStatus status={need.status} />
        </div>

        <Menu
          className="need-card__menu"
          items={needCardMenuItems}
          onClick={(e) => setNeedCardCurrentMenuTab(e.key as ENeedCardMenuItem)}
          selectedKeys={[needCardCurrentMenuTab]}
          mode="horizontal"
        />

        {needCardCurrentMenuTab === ENeedCardMenuItem.Info && (
          <NeedCardInfo
            need={need}
            canEditCount={isManager && need.status === ENeedStatus.Created}
            count={count}
            onCountChange={onCountChange}
          />
        )}

        {needCardCurrentMenuTab === ENeedCardMenuItem.Supplies && (
          <NeedCardSupplies loading={needLoading} onSupplyClick={onSupplyClick} />
        )}

        {needCardCurrentMenuTab === ENeedCardMenuItem.Updates && <div>updates</div>}

        {needCardCurrentMenuTab === ENeedCardMenuItem.Info && (
          <div className="need-card__footer">
            {isManager && need.status === ENeedStatus.Created && (
              <>
                <Button className="button-l primary" onClick={onAcceptToWorkClick} loading={needListLoading}>
                  Взять в работу
                </Button>

                <Button className="button-l secondary" onClick={onRejectClick} loading={needListLoading}>
                  Завершить
                </Button>
              </>
            )}

            {isManager && need.status === ENeedStatus.Pending && (
              <Button className="button-l danger" onClick={onRejectClick} loading={needListLoading}>
                Завершить заявку
              </Button>
            )}
          </div>
        )}
      </Drawer>

      <SuccessDrawer
        open={openSuccessDrawer}
        subtitle="Готово!"
        content={`Заявка от ${dateFormatter.fullDate(need?.createdAt)} на сумму ${priceFormatter(
          need.count * need.priceWithTaxes,
        )} взята в работу`}
        btnTitle="Назад"
        onClose={() => setOpenSuccessDrawer(false)}
        onConfirm={() => setOpenSuccessDrawer(false)}
      />

      <WarningDrawer
        open={openWarningDrawer}
        content="Вы уверены, что хотите завершить данную заявку?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Завершить"
        loading={needListLoading}
        onClose={() => {
          setOpenWarningDrawer(false);
          setOpenNeedCard(true);
        }}
        onConfirm={handleReject}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  needListLoading: state.needListState.loading,
  auth: state.authState.data,
  need: state.needState.data,
  needLoading: state.needState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeNeedStatus: dispatch.needListState.changeNeedStatus,
  getNeedSupplyList: dispatch.needState.getNeedSupplyList,
  getNeedHistoryList: dispatch.needState.getNeedHistoryList,
  updateNeed: dispatch.needState.updateNeed,
  getSupplyById: dispatch.supplyState.getSupplyById,
});

export const NeedCard = connect(mapState, mapDispatch)(NeedCardComponent);
