import type { MenuProps } from 'antd';
import { ENeedCardMenuItem, ENeedCardMenuItemLabel, ERoute } from 'common/const/enum';

type MenuItem = Required<MenuProps>['items'][number];

export const needListForManagerNavigationItems = [
  {
    label: 'Новые заявки',
    path: ERoute.NeedListForManager,
  },
  {
    label: 'Заявки в работе',
    path: ERoute.PendingNeedListForManager,
  },
  {
    label: 'Поставки',
    path: ERoute.SupplyListForManager,
  },
  {
    label: 'Завершенные поставки ',
    path: ERoute.CompletedSupplyListForManager,
  },
];

export const needCardMenuItems: MenuItem[] = [
  {
    label: ENeedCardMenuItemLabel.info,
    key: ENeedCardMenuItem.Info,
  },
  {
    label: ENeedCardMenuItemLabel.supplies,
    key: ENeedCardMenuItem.Supplies,
  },
  {
    label: ENeedCardMenuItemLabel.updates,
    key: ENeedCardMenuItem.Updates,
  },
];
