export const envConfig = (process.env.NODE_ENV === 'development' ? process.env : (window as any).env) as {
  SENTRY_DSN: string;
  NODE_ENV: string;
};

export const MIN_PASSWORD_LENGTH = 8;
export const DEFAULT_DEBOUNCE_DELAY = 800;
export const COUNT_CHANGE_DELAY = 200;
export const PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,}$/;
export const PHONE_PATTERN = /^([1-9])(\d{3})(\d{3})(\d{2})(\d{2})$/;
export const PRICE_PATTERN = /\B(?=(\d{3})+(?!\d))/g;
export const INTEGER_PATTERN = /^[0-9]+$/;
export const CHARACTER_LIMIT_MIN = 3;
export const PROPERTY_CASCADER_CHARACTER_LIMIT_MIN = 1;
export const PROPERTY_CASCADER_ITEM_PROPERTY_OFFSET = 50;
export const LEGAL_INN_LENGTH = 10;
export const LEGAL_KPP_LENGTH = 9;
export const ACCOUNT_USER_COLLECTION_LIMIT = 10;
export const DEFAULT_DELIVERY_IN_DAYS = 90;
export const DEFAULT_GOODS_COUNT = 1;
export const ALL_DOCS_EXTS = 'image/jpeg, image/png';
export const UPLOADING_DOCS_COUNT = 1;
export const GOODS_LIST_LIMIT = 20;
export const QUICKLY_GOODS_VIEW_LIMIT = 4;
export const COLLECTION_LIMIT_5 = 5;
export const JIVO_SRC = '//code.jivo.ru/widget/WgVeogLWuw';
export const DEFAULT_BASIC_DISCOUNT = 0;
export const NUMBER_REGEXP = /^[0-9\b]+$/;
export const DEFAULT_LIST_LIMIT = 20;
export const DEFAULT_LIST_OFFSET = 0;
export const DEFAULT_PAGINATION_PAGE = 1;
export const DEFAULT_COUNTER_MAX_VALUE = 10000;
export const DEFAULT_EXPANDED_ROW_KEY = -1;
export const DEFAULT_FULL_LIST_LIMIT = 0;
export const CATALOG_GOODS_LIST_FILTER_OPTIONS_COUNT_FOR_VIEW = 8;
export const DROPDOWN_ITEMS_FOR_VIEW_LIMIT = 4;
export const CATALOG_FILTER_CHIPS_MIN = 1;
export const STATISTICS_OVERFLOW_COUNT = 999;
export const SEARCH_LIMIT_MIN = 3;
export const RANGE_VALUE_MIN = '0.1';
export const CATEGORY_LIST_VISIBLE_LIMIT = 11;
export const DEFAULT_EMPTY_VALUE = -1;
