import { EBasketMenuTab, EManagerNeedListMenuTab, ERoute, ESellerManagerNeedListMenuTab } from 'common/const/enum';

export const userNavigationItems = [
  {
    label: 'заявки',
    path: ERoute.NeedListForUser,
  },
  {
    label: 'размещенные заказы',
    path: `${ERoute.NeedListForUser}/${EBasketMenuTab.SupplyList}`,
  },
];

export const managerNavigationItems = [
  {
    label: 'входящие',
    path: ERoute.NeedListForManager,
  },
  {
    label: 'в работе',
    path: `${ERoute.NeedListForManager}/${EManagerNeedListMenuTab.Pending}`,
  },
  {
    label: 'график поставок',
    path: `${ERoute.NeedListForManager}/${EManagerNeedListMenuTab.SupplyList}`,
  },
];

export const sellerNavigationItems = [
  {
    label: 'входящие',
    path: ERoute.NeedListForSeller,
  },
  {
    label: 'размещенные',
    path: `${ERoute.NeedListForSeller}/${ESellerManagerNeedListMenuTab.SupplyList}`,
  },
];
