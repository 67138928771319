import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { GoodsDeliveryBadge } from 'common/components/GoodsDeliveryBadge';
import { SuccessDrawer } from 'common/components/SuccessDrawer';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { useDebounce } from 'common/hooks/useDebounce';
import { DEFAULT_FULL_LIST_LIMIT } from 'common/config';
import { showMessage } from 'common/helpers/message.helper';
import { EDateFormat, EMessage, ERoute, EUserRole } from 'common/const/enum';
import { ReactComponent as InfoIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { RootDispatch, RootState } from 'app/store';
import { BasketEmpty } from 'entities/Basket/components/BasketEmpty';
import { BasketProgress } from 'entities/Basket/components/BasketProgress';
import { BasketRequestComposition } from 'entities/Basket/components/BasketRequestComposition';
import { BasketAddGoodsButton } from 'entities/Basket/components/BasketAddGoodsButton';
import { BasketMoreButton } from 'entities/Basket/components/BasketMoreButton';
import {
  filterBasketGoodsList,
  getBasketGoodsListDataSource,
  getBasketGoodsListRowKeys,
  getBasketGoodsPayload,
  getBasketRequestCompositionInfo,
  goodsToBasketGoods,
  renderBasketRecords,
} from 'entities/Basket/Basket.helper';
import { IBasketGoods } from 'entities/Basket/Basket.models';
import { IGoods } from 'entities/Goods/Goods.models';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const BasketComponent: FC<AllType> = (props) => {
  const {
    // state
    auth,
    basket,
    basketLoading,
    basketGoodsCount,
    requestsCount = 0,
    // dispatch
    getAddressList,
    getLegalList,
    setBasketGoodsCount,
    getBasket,
    updateBasket,
    createBasketRequest,
    updateStatisticsRequestsCount,
    updateStatisticsBasketGoodsCount,
  } = props;

  const [goodsList, setGoodsList] = useState<IBasketGoods[]>([]);
  const [selectedGoodsList, setSelectedGoodsList] = useState<IBasketGoods[]>([]);
  const [goodsId, setGoodsId] = useState<number | null>(null);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [openSuccessDrawer, setOpenSuccessDrawer] = useState<boolean>(false);
  const [openWarningDrawer, setOpenWarningDrawer] = useState<boolean>(false);
  const [openClearAllWarningDrawer, setClearAllOpenWarningDrawer] = useState<boolean>(false);
  const navigate = useNavigate();

  const isManager = auth?.access.roles.includes(EUserRole.Manager);
  const checkAll = goodsList.length === selectedGoodsList.length;
  const indeterminate = selectedGoodsList.length > 0 && selectedGoodsList.length < goodsList.length;
  const goodsListDataSource = useMemo(() => getBasketGoodsListDataSource(goodsList), [goodsList]);
  const goodsListSelectedRowKeys = useMemo(() => getBasketGoodsListRowKeys(selectedGoodsList), [selectedGoodsList]);
  const goodsListExpandedRowKeys = useMemo(() => getBasketGoodsListRowKeys(goodsList), [goodsList]);
  const { amountWithTaxes, availableAmount, limitExceeded } = useMemo(() => {
    return getBasketRequestCompositionInfo(selectedGoodsList, basket?.availableLimit);
  }, [selectedGoodsList, basket?.availableLimit]);

  const toggleOpenSuccessDrawer = () => setOpenSuccessDrawer((prev) => !prev);

  const toggleOpenWarningDrawer = () => setOpenWarningDrawer((prev) => !prev);

  const toggleOpenClearAllWarningDrawer = () => setClearAllOpenWarningDrawer((prev) => !prev);

  const onCountChange = (id: number, value: number) => {
    setGoodsList((prev) => prev.map((goods) => (id === goods.goodId ? { ...goods, count: value } : goods)));
    setSelectedGoodsList((prev) => prev.map((goods) => (id === goods.goodId ? { ...goods, count: value } : goods)));
    setCountIsChanged(true);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (indeterminate) {
      setSelectedGoodsList([]);
      return;
    }

    if (e.target.checked) {
      setSelectedGoodsList(filterBasketGoodsList.byMaxGoodPrice(goodsList, basket?.maxGoodPrice));
    } else {
      setSelectedGoodsList([]);
    }
  };

  const onGoodsSelectionChange = (id: number, checked: boolean) => {
    if (checked) {
      const goods = goodsList.find((goods) => goods.goodId === id) as IBasketGoods;

      setSelectedGoodsList((prev) => [...prev, goods]);
    } else {
      setSelectedGoodsList((prev) => filterBasketGoodsList.notEqualId(prev, id));
    }
  };

  const onDeleteGoodsClick = () => {
    if (basket && goodsId) {
      const newGoodsList = filterBasketGoodsList.notEqualId(goodsList, goodsId);

      updateBasket({
        id: basket.id,
        goods: getBasketGoodsPayload(newGoodsList),
        onSuccess: () => {
          updateStatisticsBasketGoodsCount(basketGoodsCount - 1);
          setGoodsList(newGoodsList);
          setSelectedGoodsList((prev) => filterBasketGoodsList.notEqualId(prev, goodsId));
          toggleOpenWarningDrawer();
          showMessage({ content: EMessage.GoodsHasBeenRemoved, icon: <BinIcon className="basket__message-icon" /> });
        },
      });
    }
  };

  const handleAddToBasket = (goods: IGoods, onSuccess: () => void) => {
    if (basket) {
      const goodsForBasket = goodsToBasketGoods(goods);
      const newGoodsList = [...goodsList, goodsForBasket];

      updateBasket({
        id: basket.id,
        goods: getBasketGoodsPayload(newGoodsList),
        onSuccess: () => {
          updateStatisticsBasketGoodsCount(basketGoodsCount + 1);
          setGoodsList(newGoodsList);

          if (basket?.maxGoodPrice !== undefined && basket.maxGoodPrice >= goodsForBasket.price) {
            setSelectedGoodsList((prev) => [...prev, goodsForBasket]);
          }

          onSuccess();
        },
      });
    }
  };

  const onClearBasketClick = () => {
    if (basket) {
      updateBasket({
        id: basket.id,
        goods: [],
        onSuccess: () => {
          updateStatisticsBasketGoodsCount(0);
          setGoodsList([]);
          setSelectedGoodsList([]);
          toggleOpenClearAllWarningDrawer();
          showMessage({ content: EMessage.AllGoodsHaveBeenRemoved, icon: <BinIcon className="basket__message-icon" /> });
        },
      });
    }
  };

  const onSendRequestClick = async () => {
    const newBasketGoodsCount = basketGoodsCount - selectedGoodsList.length;

    setRequestLoading(true);
    await createBasketRequest({
      goods: getBasketGoodsPayload(selectedGoodsList),
      onSuccess: () => {
        toggleOpenSuccessDrawer();
        setBasketGoodsCount(newBasketGoodsCount);

        if (isManager) {
          updateStatisticsRequestsCount(requestsCount + selectedGoodsList.length);
        }

        updateStatisticsBasketGoodsCount(newBasketGoodsCount);
        setGoodsList((prev) => {
          return prev.filter((goods) => {
            return !selectedGoodsList.some((selectedGoods) => selectedGoods.goodId === goods.goodId);
          });
        });
        setSelectedGoodsList([]);
      },
    });
    setRequestLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getBasket();

      if (response) {
        const { goods, subdivisionId, maxGoodPrice } = response;

        if (subdivisionId) {
          await getAddressList({ subdivisionId, limit: DEFAULT_FULL_LIST_LIMIT });
        }

        await getLegalList({ limit: DEFAULT_FULL_LIST_LIMIT });

        setGoodsList(goods);
        setSelectedGoodsList(filterBasketGoodsList.byMaxGoodPrice(goods, maxGoodPrice));
      }

      setFetchLoading(false);
    };

    fetch();
  }, []);

  useDebounce(() => {
    if (basket && countIsChanged) {
      updateBasket({
        id: basket.id,
        goods: getBasketGoodsPayload(goodsList),
        onSuccess: () => setCountIsChanged(false),
      });
    }
  }, [basket, goodsList, countIsChanged]);

  return (
    <div className="redesign basket">
      <div className="basket__container">
        <div className="basket__header">
          <BasketAddGoodsButton loading={basketLoading} addToBasket={handleAddToBasket} />

          <BasketMoreButton
            id={basket?.id}
            loading={basketLoading}
            clearDisabled={!basket?.goods.length}
            onDelete={toggleOpenClearAllWarningDrawer}
          />
        </div>

        <div className="basket__content">
          <div className="basket__title">
            <span className="text-h1">Корзина</span>

            <span className="text-h3 basket__title-count">{basketGoodsCount}</span>
          </div>

          {!!goodsList.length && (
            <Checkbox checked={checkAll} indeterminate={indeterminate} style={{ marginBottom: 16 }} onChange={onCheckAllChange}>
              Включить в заявку
            </Checkbox>
          )}

          <Spin wrapperClassName="basket__spin" spinning={fetchLoading} indicator={<SpinIndicator />}>
            <div className="basket__scroll">
              <div className="basket__scroll-container">
                {!!goodsList.length && (
                  <Table
                    className="table-expandable basket__goods-list"
                    rowClassName="expanded"
                    dataSource={goodsListDataSource}
                    columns={renderBasketRecords(onCountChange)}
                    rowSelection={{
                      selectedRowKeys: goodsListSelectedRowKeys,
                      onSelect: ({ goodId }, checked) => onGoodsSelectionChange(goodId, checked),
                      columnWidth: 20,
                      getCheckboxProps: ({ price }) => ({
                        disabled: basket?.maxGoodPrice !== undefined ? basket.maxGoodPrice < price : false,
                      }),
                    }}
                    expandable={{
                      showExpandColumn: false,
                      expandedRowKeys: goodsListExpandedRowKeys,
                      expandedRowRender: ({ goodId, count, remains, price }) => {
                        return (
                          <>
                            {basket?.maxGoodPrice !== undefined && basket.maxGoodPrice < price && (
                              <div className="basket__goods-list-item-notification">
                                <InfoIcon />

                                <span className="text-tag color-white">
                                  Стоимость позиции превышает допустимый лимит на цену товара.
                                </span>
                              </div>
                            )}

                            <div className="basket__goods-list-item-footer">
                              <GoodsDeliveryBadge remains={remains} count={count} />

                              <Button
                                className="button-text"
                                onClick={() => {
                                  setGoodsId(goodId);
                                  toggleOpenWarningDrawer();
                                }}
                              >
                                <span>Удалить товар</span>

                                <BinIcon />
                              </Button>
                            </div>
                          </>
                        );
                      },
                    }}
                    pagination={false}
                    showHeader={false}
                  />
                )}

                <BasketEmpty show={!basket?.goods.length && !basketLoading} />
              </div>
            </div>
          </Spin>

          {basket?.availableLimit && (
            <BasketProgress
              limit={basket?.availableLimit}
              amount={amountWithTaxes}
              availableAmount={availableAmount}
              limitExceeded={limitExceeded}
            />
          )}
        </div>
      </div>

      {!!goodsList.length && (
        <BasketRequestComposition
          basketId={basket?.id}
          addressId={basket?.addressId}
          legalId={basket?.legalId}
          selectedGoodsList={selectedGoodsList}
          limitExceeded={limitExceeded}
          loading={requestLoading}
          onSendClick={onSendRequestClick}
        />
      )}

      <SuccessDrawer
        open={openSuccessDrawer}
        content={`Заявка от ${dayjs().format(EDateFormat.FullDateDotSeparator)} в очереди на рассмотрение`}
        subtitle="Отправлено!"
        btnTitle="К моим заявкам"
        onClose={toggleOpenSuccessDrawer}
        onConfirm={() => navigate(ERoute.NeedListForUser)}
      />

      <WarningDrawer
        open={openWarningDrawer}
        content="Вы уверены, что хотите удалить товар?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={basketLoading}
        onClose={!basketLoading ? toggleOpenWarningDrawer : undefined}
        onConfirm={onDeleteGoodsClick}
      />

      <WarningDrawer
        open={openClearAllWarningDrawer}
        content="Вы уверены, что хотите очистить корзину?"
        subtitle="Все товары будут безвозвратно удалены."
        confirmBtnTitle="Очистить"
        loading={basketLoading}
        onClose={!basketLoading ? toggleOpenClearAllWarningDrawer : undefined}
        onConfirm={onClearBasketClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  auth: state.authState.data,
  basket: state.basketState.data,
  basketLoading: state.basketState.loading,
  basketGoodsCount: state.basketState.goodsCount,
  requestsCount: state.statisticsState.data.requestsCount,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getAddressList: dispatch.addressListState.getAddressList,
  getLegalList: dispatch.legalListState.getLegalList,
  setBasketGoodsCount: dispatch.basketState.setBasketGoodsCount,
  getBasket: dispatch.basketState.getBasket,
  updateBasket: dispatch.basketState.updateBasket,
  createBasketRequest: dispatch.basketState.createBasketRequest,
  updateStatisticsRequestsCount: dispatch.statisticsState.updateStatisticsRequestsCount,
  updateStatisticsBasketGoodsCount: dispatch.statisticsState.updateStatisticsBasketGoodsCount,
});

export const Basket = connect(mapState, mapDispatch)(BasketComponent);
