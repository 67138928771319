import React, { FC } from 'react';
import { Button, Dropdown } from 'antd';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';

interface IComponentProps {
  id?: number;
  loading: boolean;
  clearDisabled: boolean;
  onDelete: () => void;
}

export const BasketMoreButton: FC<IComponentProps> = ({ id, clearDisabled, onDelete }) => {
  const onCopyClick = () => {
    if (id) {
      navigator.clipboard.writeText(id.toString());
    }
  };

  return (
    <Dropdown
      overlayClassName="redesign basket-more-button"
      menu={{
        items: [
          {
            key: 'copy',
            label: (
              <div className="basket-more-button__menu-item">
                <span className="text-tag color-dark-grey">Код корзины</span>

                <div className="basket-more-button__menu-item-container">
                  <span className="text-h4-item-name">{id}</span>

                  <CopyIcon />
                </div>
              </div>
            ),
            onClick: onCopyClick,
          },
          {
            type: 'divider',
          },
          {
            key: 'clear',
            label: <span className="text-lists color-red">Очистить корзину</span>,
            onClick: onDelete,
            disabled: clearDisabled,
          },
        ],
      }}
      trigger={['click']}
      placement="bottomRight"
      dropdownRender={(menus) => {
        return (
          <div className="custom-dropdown" style={{ width: 280 }}>
            {menus}
          </div>
        );
      }}
    >
      <Button className="button-circle secondary" icon={<OptionsIcon />} />
    </Dropdown>
  );
};
