import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { EManagerNeedListMenuTab } from 'common/const/enum';
import { needListForManagerNavigationItems } from 'entities/Need/Need.const';
import { NeedListNavigation } from 'entities/Need/components/NeedListNavogation';
import { CreatedNeedListForManager } from 'entities/Need/components/CreatedNeedListForManager';
import { PendingNeedListForManager } from 'entities/Need/components/PendingNeedListForManager';
import { SupplyListForManager } from 'entities/Supply/components/SupplyListForManager';
import { CompletedSupplyListForManager } from 'entities/Supply/components/CompletedSupplyListForManager';

export const NeedListForManager: FC = () => {
  const { tab } = useParams();

  const renderContent = () => {
    switch (tab) {
      case EManagerNeedListMenuTab.Pending: {
        return <PendingNeedListForManager />;
      }
      case EManagerNeedListMenuTab.SupplyList: {
        return <SupplyListForManager />;
      }
      case EManagerNeedListMenuTab.CompletedSupplyList: {
        return <CompletedSupplyListForManager />;
      }
      default: {
        return <CreatedNeedListForManager />;
      }
    }
  };

  return (
    <div className="redesign need-list">
      <div className="text-body color-dark-grey" style={{ marginBottom: 20 }}>
        Заявки на закупку
      </div>

      <NeedListNavigation items={needListForManagerNavigationItems} />

      {renderContent()}
    </div>
  );
};
