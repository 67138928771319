import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Select, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { mapToMenuWithDivider } from 'common/helpers/data.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { EMessage, EPlaceholder } from 'common/const/enum';
import { ReactComponent as ExportIcon } from 'app/assets/images/redesign/export.svg';
import { ReactComponent as InfoIcon } from 'app/assets/images/redesign/info-red.svg';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { RootDispatch, RootState } from 'app/store';
import {
  getBasketAddressOptions,
  getBasketRequestCompositionInfo,
  renderBasketRequestCompositionRecords,
} from 'entities/Basket/Basket.helper';
import { IBasketGoods } from 'entities/Basket/Basket.models';

interface IComponentProps {
  basketId?: number;
  addressId?: number;
  legalId?: number;
  selectedGoodsList: IBasketGoods[];
  limitExceeded: boolean;
  loading: boolean;
  onSendClick: () => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BasketRequestCompositionComponent: FC<AllType> = (props) => {
  const {
    // props
    basketId,
    addressId,
    legalId,
    selectedGoodsList,
    limitExceeded,
    loading,
    onSendClick,
    // state
    addressList,
    legalList,
    // dispatch
    updateBasket,
    uploadBasketExcel,
  } = props;

  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [selectedLegalId, setSelectedLegalId] = useState<number | null>(null);
  const { count, amount, amountWithTaxes } = useMemo(() => {
    return getBasketRequestCompositionInfo(selectedGoodsList);
  }, [selectedGoodsList]);

  const addressOptions = getBasketAddressOptions(addressList, selectedAddressId);
  const legalOptions = legalList.map((legal) => ({ label: legal.name, value: legal.id }));

  const onAddressChange = (id: number) => {
    setSelectedAddressId(id);

    if (basketId) {
      updateBasket({
        id: basketId,
        addressId: id,
        onSuccess: () => {
          showSuccessMessage(EMessage.DeliveryAddressSuccessfullyChanged);
        },
      });
    }
  };

  const onLegalChange = (id: number) => {
    setSelectedLegalId(id);

    if (basketId) {
      updateBasket({
        id: basketId,
        legalId: id,
        onSuccess: () => {
          showSuccessMessage(EMessage.LegalSuccessfullyChanged);
        },
      });
    }
  };

  useEffect(() => {
    if (addressId) {
      setSelectedAddressId(addressId);
    }

    if (legalId) {
      setSelectedLegalId(legalId);
    }
  }, [addressId, legalId]);

  return (
    <div className="basket-request-composition">
      <div className="basket-request-composition__header">
        <Tooltip title="Экспорт в Excel">
          <Button
            className="button-circle secondary"
            icon={<ExportIcon />}
            onClick={() => uploadBasketExcel(basketId as number)}
          />
        </Tooltip>
      </div>

      <div className="basket-request-composition__body">
        <div className="basket-request-composition__body-container">
          <span className="text-h1 basket-request-composition__body-title">Состав заявки</span>

          <div className="basket-request-composition__body-block">
            <span className="text-h4 basket-request-composition__body-block-title">Детали доставки</span>

            <Table
              className="basket-request-composition__body-block-table selectable"
              pagination={false}
              showHeader={false}
              size="small"
              dataSource={[
                {
                  key: 'address',
                  label: <span className="text-body color-dark-grey">Адрес:</span>,
                  content: (
                    <Select
                      rootClassName="redesign"
                      className="basket-request-composition__body-block-select"
                      popupClassName="basket-request-composition__body-block-select-popup"
                      options={mapToMenuWithDivider(addressOptions)}
                      value={selectedAddressId}
                      onChange={onAddressChange}
                      suffixIcon={<ArrowDownIcon />}
                      popupMatchSelectWidth={false}
                      optionLabelProp="desc"
                      placeholder={EPlaceholder.SelectAddress}
                      notFoundContent={<span className="text-lists color-dark-grey">В списке нет вариантов.</span>}
                    />
                  ),
                },
                {
                  key: 'legal',
                  label: <span className="text-body color-dark-grey">Юридическое лицо:</span>,
                  content: (
                    <Select
                      rootClassName="redesign"
                      className="basket-request-composition__body-block-select"
                      popupClassName="basket-request-composition__body-block-select-popup"
                      options={mapToMenuWithDivider(legalOptions)}
                      value={selectedLegalId}
                      onChange={onLegalChange}
                      suffixIcon={<ArrowDownIcon />}
                      popupMatchSelectWidth={false}
                      placeholder={EPlaceholder.SelectLegal}
                      notFoundContent={<span className="text-lists color-dark-grey">В списке нет вариантов.</span>}
                    />
                  ),
                },
              ]}
              columns={renderBasketRequestCompositionRecords()}
            />
          </div>

          <div className="basket-request-composition__body-block">
            <span className="text-h4 basket-request-composition__body-block-title">Информация о заявке</span>

            <div className="basket-request-composition__body-block-container">
              <div className="basket-request-composition__body-block-container-row">
                <span className="text-body color-dark-grey">Товаров в заявке:</span>

                <span className="text-body">{countFormatter(count)}</span>
              </div>

              <div className="basket-request-composition__body-block-container-row">
                <span className="text-body color-dark-grey">Стоимость товаров:</span>

                <span className="text-body">{priceFormatter(amount)}</span>
              </div>

              <div className="basket-request-composition__body-block-container-row">
                <span className="text-body color-dark-grey">Сумма НДС:</span>

                <span className="text-body">{priceFormatter(amountWithTaxes - amount)}</span>
              </div>

              <div className="basket-request-composition__body-block-container-row">
                <span className={`text-body ${limitExceeded ? 'color-red' : 'color-dark-grey'}`}>Итого с НДС:</span>

                <span className={`text-body ${limitExceeded ? 'color-red' : ''}`}>{priceFormatter(amountWithTaxes)}</span>
              </div>
            </div>
          </div>

          {limitExceeded && (
            <div className="basket-request-composition__notification">
              <InfoIcon />

              <div className="basket-request-composition__notification-container">
                <span className="text-h4 color-white">Ваша заявка превышает доступный лимит на покупки</span>

                <span className="text-tag color-white">
                  Сократите количество товаров в списке или замените их на позиции с более низкой ценой.
                </span>
              </div>
            </div>
          )}
        </div>

        {!limitExceeded && (
          <div className="basket-request-composition__body-footer">
            <Button className="button-l primary" onClick={onSendClick} loading={loading} disabled={!selectedGoodsList.length}>
              Отправить
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  addressList: state.addressListState.data,
  legalList: state.legalListState.data,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateBasket: dispatch.basketState.updateBasket,
  uploadBasketExcel: dispatch.basketState.uploadBasketExcel,
});

export const BasketRequestComposition = connect(mapState, mapDispatch)(BasketRequestCompositionComponent);
