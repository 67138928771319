/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';
import { DEFAULT_PAGINATION_PAGE } from 'common/config';
import { ENeedCardMenuItem } from 'common/const/enum';
import { INeed } from 'entities/Need/Need.models';

export interface INeedContext {
  page: number;
  setPage: (value: number) => void;
  needCardCurrentMenuTab: ENeedCardMenuItem;
  setNeedCardCurrentMenuTab: (value: ENeedCardMenuItem) => void;
  openNeedCard: boolean;
  setOpenNeedCard: (value: boolean) => void;
  fetchLoading: boolean;
  setFetchLoading: (value: boolean) => void;
  selectedNeedList: INeed[];
  setSelectedNeedList: (value: INeed[]) => void;
}

export const NeedContext = createContext<INeedContext>({
  page: DEFAULT_PAGINATION_PAGE,
  setPage: () => {},
  needCardCurrentMenuTab: ENeedCardMenuItem.Info,
  setNeedCardCurrentMenuTab: () => {},
  openNeedCard: false,
  setOpenNeedCard: () => {},
  fetchLoading: false,
  setFetchLoading: () => {},
  selectedNeedList: [],
  setSelectedNeedList: () => {},
});

export const useNeedContext = () => useContext(NeedContext);

export interface IComponentProps {
  children: React.ReactNode;
}

export const NeedProvider: FC<IComponentProps> = ({ children }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);
  const [needCardCurrentMenuTab, setNeedCardCurrentMenuTab] = useState<ENeedCardMenuItem>(ENeedCardMenuItem.Info);
  const [openNeedCard, setOpenNeedCard] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [selectedNeedList, setSelectedNeedList] = useState<INeed[]>([]);

  return (
    <NeedContext.Provider
      value={{
        page,
        setPage,
        needCardCurrentMenuTab,
        setNeedCardCurrentMenuTab,
        openNeedCard,
        setOpenNeedCard,
        fetchLoading,
        setFetchLoading,
        selectedNeedList,
        setSelectedNeedList,
      }}
    >
      {children}
    </NeedContext.Provider>
  );
};
