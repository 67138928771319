import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Checkbox, Input, Pagination, Select, Spin } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { PaginationProps } from 'antd';
import { connect } from 'react-redux';
import { ContentLoader } from 'common/components/ContentLoader';
import { useContentLoader } from 'common/hooks/useContentLoader';
import { EMessage, ERoute, ESupplyStatus } from 'common/const/enum';
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { GoodsItem } from 'common/components/GoodsItem';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ChevronIcon } from 'app/assets/images/chevron.svg';
import { ReactComponent as SearchIcon } from 'app/assets/images/search.svg';
import { ISupplyListPayload } from 'entities/Supply/Supply.models';
import { supplyListStatusFilterOptions } from 'entities/Supply/Supply.const';
import { getSupplyChangeStatusMenuItems, getSupplyStatus } from 'entities/Supply/Supply.helper';
import { SupplyListSummary } from 'entities/Supply/components/SupplyListSummary';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SupplyListForSellerManagerComponent: FC<AllType> = (props) => {
  const {
    supplyListState,
    buyerListState,
    getSupplyList,
    setSupplyList,
    filterSupplyList,
    changeSupplyStatus,
    getSupplySubdivisionListForSeller,
    getBuyerList,
  } = props;

  const { data: supplyList, loading: supplyListLoading, count: supplyListCount, subdivisionList } = supplyListState;
  const { data: buyerList } = buyerListState;

  const [payload, setPayload] = useState<ISupplyListPayload>({
    statuses: [ESupplyStatus.Approved],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });
  const [selectedSupplyIds, setSelectedSupplyIds] = useState<number[]>([]);
  const [page, setPage] = useState<number>(DEFAULT_PAGINATION_PAGE);

  const checkAll = !!supplyList.length && supplyList.length === selectedSupplyIds.length;
  const selectedSupplyList = supplyList.filter((supply) => selectedSupplyIds.includes(supply.id));
  const buyerOptions = buyerList.map((buyer) => ({ label: buyer.name, value: buyer.id }));
  const subdivisionOptions = subdivisionList.map((buyer) => ({ label: buyer.name, value: buyer.id }));

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPayload = { ...payload, search: e.target.value, offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
  };

  const onCompanyChange = async (value?: number) => {
    const newPayload = {
      ...payload,
      accountId: value,
      subdivisionIds: undefined,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPayload(newPayload);
    await getSupplyList(newPayload);
    await getSupplySubdivisionListForSeller({ buyerId: value });
    setSelectedSupplyIds([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onSubdivisionChange = async (value?: number) => {
    const newPayload = {
      ...payload,
      subdivisionIds: value ? [value] : undefined,
      offset: DEFAULT_LIST_OFFSET,
    };

    setPayload(newPayload);
    await getSupplyList(newPayload);
    setSelectedSupplyIds([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onFilterStatusChange = async (value: ESupplyStatus) => {
    const newPayload = { ...payload, statuses: [value], offset: DEFAULT_LIST_OFFSET };

    setPayload(newPayload);
    await getSupplyList(newPayload);
    setSelectedSupplyIds([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checkedList = supplyList.map((supply) => supply.id);
    setSelectedSupplyIds(e.target.checked ? checkedList : []);
  };

  const onPageChange: PaginationProps['onChange'] = async (value) => {
    const newPayload = { ...payload, offset: (value - 1) * DEFAULT_LIST_LIMIT };

    setPayload(newPayload);
    await getSupplyList(newPayload);
    setSelectedSupplyIds([]);
    setPage(value);
  };

  const onSupplyStatusChange = (id: number, value: ESupplyStatus) => {
    changeSupplyStatus({
      ids: [id],
      status: value,
      onSuccess: () => {
        filterSupplyList([id]);

        if (selectedSupplyIds.includes(id)) {
          setSelectedSupplyIds((prev) => prev.filter((item) => item !== id));
        }

        if (selectedSupplyIds.length === supplyList.length) {
          setPage(DEFAULT_PAGINATION_PAGE);
        }
      },
    });
  };

  const onSelectedSupplyStatusChange = (value: ESupplyStatus) => {
    changeSupplyStatus({
      ids: selectedSupplyIds,
      status: value,
      onSuccess: () => {
        filterSupplyList(selectedSupplyIds);
        setSelectedSupplyIds([]);

        if (selectedSupplyIds.length === supplyList.length) {
          setPage(DEFAULT_PAGINATION_PAGE);
        }
      },
    });
  };

  useEffect(() => {
    return () => {
      setSupplyList([]);
    };
  }, []);

  useDebounce(async () => {
    await getSupplyList(payload);
    setSelectedSupplyIds([]);
    setPage(DEFAULT_PAGINATION_PAGE);
  }, [payload.search]);

  const { contentLoading } = useContentLoader(async () => {
    await getSupplyList(payload);
    await getBuyerList();
    await getSupplySubdivisionListForSeller({});
  });

  if (contentLoading) {
    return (
      <div className="supply-list">
        <ContentLoader />
      </div>
    );
  }

  return (
    <div className="supply-list">
      <div className="supply-list__content">
        <div className="supply-list__filter">
          <Input
            className="supply-list__filter-search"
            prefix={<SearchIcon />}
            placeholder="Search"
            value={payload.search}
            onChange={onSearchChange}
          />

          <div className="supply-list__filter-item">
            <span className="supply-list__filter-item-label">Компания</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.accountId}
              options={buyerOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите компанию"
              allowClear
              onChange={onCompanyChange}
            />
          </div>

          <div className="need-list__filter-item">
            <span className="need-list__filter-item-label">Подразделение</span>

            <Select
              suffixIcon={<ChevronIcon />}
              value={payload.subdivisionIds?.[0]}
              options={subdivisionOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите подразделение"
              allowClear
              onChange={onSubdivisionChange}
            />
          </div>

          <div className="supply-list__filter-item">
            <span className="supply-list__filter-item-label">Статус</span>

            <Select
              suffixIcon={<ChevronIcon />}
              // @ts-ignore
              value={payload.statuses?.[0]}
              options={supplyListStatusFilterOptions}
              popupMatchSelectWidth={false}
              placeholder="Выберите статус"
              onChange={onFilterStatusChange}
            />
          </div>
        </div>

        <div className="supply-list__btn-container">
          <Checkbox checked={checkAll} onChange={onCheckAllChange} disabled={payload.statuses?.includes(ESupplyStatus.Cancelled)}>
            Выделить все / Снять выделение
          </Checkbox>
        </div>

        <Spin spinning={supplyListLoading}>
          <>
            {supplyList.length ? (
              supplyList.map((supply) => {
                const checked = selectedSupplyIds.includes(supply.id);
                const { supplyApproved, supplyCancelled } = getSupplyStatus(supply.status);

                return (
                  <GoodsItem
                    key={supply.id}
                    id={supply.id}
                    options={{
                      canBeSelected: !supplyCancelled,
                      showVendorCode: true,
                      showCount: true,
                      showTotalAmount: true,
                      showSupplyStatus: true,
                      showDeliveryDate: true,
                      showMenu: !(supplyApproved || supplyCancelled),
                      showCompanyName: true,
                      showCity: true,
                      showOrderNumber: true,
                      showLink: true,
                      checked,
                      image: supply.good.image,
                      name: supply.good.name,
                      vendorCode: supply.good.vendorCode,
                      count: supply.count,
                      price: supply.price,
                      status: supply.status,
                      deliveryDate: supply.deliveryDate,
                      menuItems: getSupplyChangeStatusMenuItems((value) => onSupplyStatusChange(supply.id, value), supply.status),
                      companyName: supply.account.name,
                      city: supply.address?.city,
                      orderNumber: supply.specificationId,
                      link: `${ERoute.NeedListForSeller}?id=${supply.supplyNeedId}`,
                    }}
                    changeChecked={(id, value) => {
                      setSelectedSupplyIds((prev) => (value ? [...prev, id] : prev.filter((item) => item !== id)));
                    }}
                  />
                );
              })
            ) : (
              <div className="supply-list__empty">{EMessage.RequestListIsEmpty}</div>
            )}

            <Pagination total={supplyListCount} current={page} pageSize={DEFAULT_LIST_LIMIT} onChange={onPageChange} />
          </>
        </Spin>
      </div>

      <SupplyListSummary
        selectedList={selectedSupplyList}
        status={payload.statuses?.[0]}
        onStatusChange={onSelectedSupplyStatusChange}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  supplyListState: state.supplyListState,
  buyerListState: state.buyerListState,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplyList: dispatch.supplyListState.getSupplyList,
  setSupplyList: dispatch.supplyListState.setSupplyList,
  filterSupplyList: dispatch.supplyListState.filterSupplyList,
  changeSupplyStatus: dispatch.supplyListState.changeSupplyStatus,
  getSupplySubdivisionListForSeller: dispatch.supplyListState.getSupplySubdivisionListForSeller,
  getBuyerList: dispatch.buyerListState.getBuyerList,
});

export const SupplyListForSellerManager = connect(mapState, mapDispatch)(SupplyListForSellerManagerComponent);
