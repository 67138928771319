import React, { FC } from 'react';
import { ESupplyStatus, ESupplyStatusLabel } from 'common/const/enum';
import { ReactComponent as DeliveryIcon } from 'app/assets/images/redesign/delivery.svg';

interface IComponentProps {
  status: string;
}

export const SupplyStatus: FC<IComponentProps> = ({ status }) => (
  <div className={`supply-status ${status}`}>
    <DeliveryIcon />

    <span className="text-tag-accent">{ESupplyStatusLabel[status as ESupplyStatus]}</span>
  </div>
);
