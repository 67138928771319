import axios from 'axios';
import {
  ISupply,
  ISupplyChangeStatusPayload,
  ISupplyCollectionDto,
  ISupplyCorrectionPayload,
  ISupplyCreatePayload,
  ISupplyDeletePayload,
  ISupplyListPayload,
  ISupplySubdivisionListForManagerParams,
  ISupplySubdivisionListForSellerParams,
  ISupplySubdivisionsCollectionDto,
  ISupplyUpdatePayload,
  ISupplyUserListParams,
  ISupplyUsersCollectionDto,
} from 'entities/Supply/Supply.models';

const basePath = 'supply';

export const supplyTransport = {
  getSupplyList: (payload: ISupplyListPayload): Promise<ISupplyCollectionDto> => axios.post(`${basePath}/list`, payload),
  getSupplyById: (id: number): Promise<ISupply> => axios.get(`${basePath}/${id}`),
  createSupply: (payload: ISupplyCreatePayload): Promise<ISupplyCollectionDto> => axios.post(`${basePath}`, payload),
  updateSupply: (payload: ISupplyUpdatePayload): Promise<ISupply> => axios.patch(`${basePath}`, payload),
  changeSupplyStatus: (payload: ISupplyChangeStatusPayload): Promise<ISupplyCollectionDto> =>
    axios.post(`${basePath}/change-status`, payload),
  deleteSupply: (payload: ISupplyDeletePayload): Promise<ISupply> => axios.delete(`${basePath}`, { data: payload }),
  sendRequestSupplyCorrection: (payload: ISupplyCorrectionPayload): Promise<ISupply> =>
    axios.post(`${basePath}/correction`, payload),
  getSupplyUserList: (params: ISupplyUserListParams): Promise<ISupplyUsersCollectionDto> =>
    axios.get(`${basePath}/users`, { params }),
  getSupplySubdivisionListForManager: (
    params: ISupplySubdivisionListForManagerParams,
  ): Promise<ISupplySubdivisionsCollectionDto> => axios.get(`${basePath}/subdivisions`, { params }),
  getSupplySubdivisionListForSeller: (params: ISupplySubdivisionListForSellerParams): Promise<ISupplySubdivisionsCollectionDto> =>
    axios.get(`${basePath}/subdivisions/seller`, { params }),
};
