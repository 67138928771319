import React from 'react';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { Image } from 'common/components/Image';
import { NameLabel } from 'common/components/NameLabel';
import { DEFAULT_EMPTY_VALUE } from 'common/config';
import { EDateFormat, ENeedHistoryType, ENeedStatusLabel, ESupplyCorrectionLabel, ESupplyStatusLabel } from 'common/const/enum';
import { mapToMenuWithDivider } from 'common/helpers/data.helper';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { NeedListItemProgress } from 'entities/Need/components/NeedListItemProgress';
import {
  INeed,
  INeedHistoryItem,
  INeedHistoryNeedChanges,
  INeedHistorySupplyChanges,
  INeedHistorySupplyCorrectionChanges,
  INeedListState,
  INeedSubdivision,
  INeedUserListItem,
} from 'entities/Need/Need.models';
import { getUserInitials, getUserName } from 'entities/Users/Users.helper';

export const getNeedListSubdivisionOptions = (subdivisionList: INeedSubdivision[]) => {
  return mapToMenuWithDivider([
    { label: 'Все подразделения', value: DEFAULT_EMPTY_VALUE },
    ...subdivisionList.map((subdivision) => ({ label: subdivision.name, value: subdivision.id })),
  ]);
};

export const getNeedListUserOptions = (userList: INeedUserListItem[]) => {
  return mapToMenuWithDivider([
    { label: 'Все пользователи', value: DEFAULT_EMPTY_VALUE },
    ...userList.map((user) => ({ label: getUserName(user.firstName, user.lastName), value: user.id })),
  ]);
};

export const updateNeedListState = {
  setNeedList: (state: INeedListState, payload: INeed[]) => ({ ...state, data: payload }),
  setNeedListPart: (state: INeedListState, payload: INeed[]) => ({ ...state, data: [...state.data, ...payload] }),
  setNeedListCount: (state: INeedListState, payload: number) => ({ ...state, count: payload }),
  setNeedListLoading: (state: INeedListState, payload: boolean) => ({ ...state, loading: payload }),
  setNeedUserList: (state: INeedListState, payload: INeedUserListItem[]) => ({ ...state, userList: payload }),
  setNeedUserListLoading: (state: INeedListState, payload: boolean) => ({ ...state, userListLoading: payload }),
  setNeedSubdivisionList: (state: INeedListState, payload: INeedSubdivision[]) => ({
    ...state,
    subdivisionList: payload,
  }),
  setNeedSubdivisionListLoading: (state: INeedListState, payload: boolean) => ({ ...state, subdivisionListLoading: payload }),
  filterNeedList: (state: INeedListState, payload: number[]) => {
    return {
      ...state,
      data: state.data.filter((need) => !payload.includes(need.id)),
      count: state.count - payload.length,
    };
  },
  updateNeedList: (state: INeedListState, payload: INeed[]) => {
    return {
      ...state,
      data: state.data.map((need) => {
        const item = payload.find((needItem) => needItem.id === need.id);

        return item ? item : need;
      }),
    };
  },
  updateNeedListItemPendingSuppliesCount: (state: INeedListState, payload: { id: number; count: number }) => {
    return {
      ...state,
      data: state.data.map((need) => (need.id === payload.id ? { ...need, pendingSuppliesCount: payload.count } : need)),
    };
  },
};

export const getNeedHistoryItemDescription = (item: INeedHistoryItem) => {
  switch (item.type) {
    case ENeedHistoryType.ChangeSupplyNeedStatus: {
      const { oldStatus, newStatus } = item.changes as INeedHistoryNeedChanges;

      return `Изменён статус заявки: ${ENeedStatusLabel[oldStatus]} -> ${ENeedStatusLabel[newStatus]}`;
    }
    case ENeedHistoryType.CreateSupply: {
      const { newStatus } = item.changes as INeedHistorySupplyChanges;

      return `Создана новая поставка. Статус: ${ESupplyStatusLabel[newStatus]}`;
    }
    case ENeedHistoryType.ChangeSupplyStatus: {
      const { oldStatus, newStatus } = item.changes as INeedHistorySupplyChanges;

      return `Изменён статус поставки: ${ESupplyStatusLabel[oldStatus]} -> ${ESupplyStatusLabel[newStatus]}`;
    }
    case ENeedHistoryType.CreateSupplyCorrection: {
      const { reason, comment } = item.changes as INeedHistorySupplyCorrectionChanges;

      // @ts-ignore
      let content = `Запрос корректировки: ${ESupplyCorrectionLabel[reason]}.`;

      if (comment) {
        content += ` Комментарий: ${comment}`;
      }

      return content;
    }
    case ENeedHistoryType.CreateSupplyNeed: {
      return `Создана новая заявка.`;
    }
    default: {
      return '';
    }
  }
};

export const getNeedCardFormattedDate = (createdAt: string) => {
  if (dayjs().isSame(createdAt, 'day')) {
    return 'Сегодня';
  }

  return dayjs(createdAt).format(EDateFormat.FullDateDotSeparator);
};

export const renderCreatedNeedListForManagerRecords = () => {
  return [
    {
      key: 'image',
      render: (_, { good: { image } }) => <Image size={60} src={image} />,
    },
    {
      key: 'name',
      render: (_, { good: { name } }) => {
        return <span className="text-h4-item-name">{name}</span>;
      },
      width: 436,
    },
    {
      key: 'code',
      render: (_, { good: { sellerCode, vendorCode } }) => {
        return (
          <div>
            {sellerCode && <div className="text-lists">{sellerCode}</div>}

            {vendorCode && <div className="text-tag-accent color-bright-green">{vendorCode}</div>}
          </div>
        );
      },
    },
    {
      key: 'count',
      render: (_, { count, price }) => {
        return (
          <div>
            <div className="text-lists">{count}</div>

            <div className="text-tag color-dark-grey">{`${priceFormatter(price)}/ед.`}</div>
          </div>
        );
      },
    },
    {
      key: 'totalPrice',
      render: (_, { count, price, priceWithTaxes }) => {
        return (
          <div>
            <div className="text-lists">{priceFormatter(count * price)}</div>

            <div className="text-tag color-dark-grey">{`сумма с НДС ${priceFormatter(count * priceWithTaxes)}`}</div>
          </div>
        );
      },
    },
    {
      key: 'user',
      render: (_, { user: { firstName, lastName } }) => {
        return <NameLabel icon={getUserInitials(firstName, lastName)} name={getUserName(firstName, lastName)} />;
      },
    },
  ] as ColumnsType<INeed>;
};

export const renderPendingNeedListForManagerRecords = () => {
  return [
    {
      key: 'image',
      render: (_, { good: { image } }) => <Image size={60} src={image} />,
    },
    {
      key: 'name',
      render: (_, { good: { name } }) => {
        return <span className="text-h4-item-name">{name}</span>;
      },
      width: 436,
    },
    {
      key: 'code',
      render: (_, { good: { sellerCode, vendorCode } }) => {
        return (
          <div>
            {sellerCode && <div className="text-lists">{sellerCode}</div>}

            {vendorCode && <div className="text-tag-accent color-bright-green">{vendorCode}</div>}
          </div>
        );
      },
    },
    {
      key: 'user',
      render: (_, { user: { firstName, lastName } }) => {
        return <NameLabel icon={getUserInitials(firstName, lastName)} name={getUserName(firstName, lastName)} />;
      },
    },
    {
      key: 'approvedSuppliesCount',
      render: (_, { count, pendingSuppliesCount }) => {
        return <NeedListItemProgress label="Подтверждено" count={pendingSuppliesCount} limit={count} />;
      },
      width: 320,
    },
  ] as ColumnsType<INeed>;
};

export const getSelectedNeedListTotalAmount = (list: INeed[]) =>
  list.reduce((acc, need) => acc + need.count * need.priceWithTaxes, 0);
