import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Select, Table } from 'antd';
import { connect } from 'react-redux';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { useSupplyContext } from 'common/hooks/useSupplyContext';
import { useNeedContext } from 'common/hooks/useNeedContext';
import { ESupplyStatus } from 'common/const/enum';
import { DEFAULT_EMPTY_VALUE, DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE } from 'common/config';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as SearchIcon } from 'app/assets/images/redesign/search.svg';
import { ReactComponent as ArrowDownIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { SupplyCard } from 'entities/Supply/components/SupplyCard';
import { ISupplyListPayload } from 'entities/Supply/Supply.models';
import {
  getSupplyListSubdivisionOptions,
  getSupplyListUserOptions,
  renderSupplyListForManagerRecords,
} from 'entities/Supply/Supply.helper';
import { supplyListFilterStatusOptionsForManager } from 'entities/Supply/Supply.const';
import { NeedCard } from 'entities/Need/components/NeedCard';
import { SupplyCorrectionRequestDrawer } from 'entities/Supply/components/SupplyCorrectionRequestDrawer';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SupplyListForManagerComponent: FC<AllType> = (props) => {
  const {
    // state
    subdivisionList,
    userList,
    supplyList,
    supplyListLoading,
    supplyListCount,
    // dispatch
    getSupplySubdivisionListForManager,
    getSupplyUserList,
    getSupplyList,
    getSupplyListPart,
    getSupplyById,
    getNeedById,
  } = props;

  const { page, setPage, setOpenSupplyCard, fetchLoading, setFetchLoading } = useSupplyContext();
  const { setOpenNeedCard } = useNeedContext();

  const [payload, setPayload] = useState<ISupplyListPayload>({
    statuses: [
      ESupplyStatus.Created,
      ESupplyStatus.Pending,
      ESupplyStatus.Approved,
      ESupplyStatus.Placed,
      ESupplyStatus.Delivery,
    ],
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  });

  const subdivisionOptions = getSupplyListSubdivisionOptions(subdivisionList);
  const userOptions = getSupplyListUserOptions(userList);

  const onSubdivisionChange = async (value: number) => {
    const newPayload = { ...payload, subdivisionIds: value === DEFAULT_EMPTY_VALUE ? undefined : [value], userId: undefined };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    await getSupplyList(newPayload);
    await getSupplyUserList({ statuses: payload.statuses, subdivisionId: value === DEFAULT_EMPTY_VALUE ? undefined : value });
  };

  const onUserChange = (value: number) => {
    const newPayload = { ...payload, userId: value === DEFAULT_EMPTY_VALUE ? undefined : value };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    getSupplyList(newPayload);
  };

  const onStatusChange = (value: number | string) => {
    const newPayload = {
      ...payload,
      statuses:
        value === DEFAULT_EMPTY_VALUE
          ? [ESupplyStatus.Created, ESupplyStatus.Pending, ESupplyStatus.Approved, ESupplyStatus.Placed, ESupplyStatus.Delivery]
          : [value as ESupplyStatus],
    };

    setPayload(newPayload);
    setPage(DEFAULT_PAGINATION_PAGE);
    getSupplyList(newPayload);
  };

  const onSearchClick = () => {
    setPage(DEFAULT_PAGINATION_PAGE);
    getSupplyList(payload);
  };

  useEffect(() => {
    const fetch = async () => {
      setFetchLoading(true);
      await getSupplySubdivisionListForManager({ statuses: payload.statuses });
      await getSupplyUserList({ statuses: payload.statuses });
      await getSupplyList(payload);
      setFetchLoading(false);
    };

    fetch();
  }, []);

  return (
    <>
      <div className="supply-list__filters">
        <Select
          rootClassName="redesign"
          popupClassName="supply-list__filters-select-popup"
          className="supply-list__filters-select"
          options={subdivisionOptions}
          value={payload.subdivisionIds ? payload.subdivisionIds[0] : DEFAULT_EMPTY_VALUE}
          onChange={onSubdivisionChange}
          placeholder="Выберите подразделение"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="supply-list__filters-select-popup"
          className="supply-list__filters-select"
          options={userOptions}
          value={payload.userId ? payload.userId : DEFAULT_EMPTY_VALUE}
          onChange={onUserChange}
          placeholder="Выберите пользователя"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Select
          rootClassName="redesign"
          popupClassName="supply-list__filters-select-popup"
          className="supply-list__filters-select"
          options={supplyListFilterStatusOptionsForManager}
          value={payload.statuses && payload.statuses.length === 1 ? payload.statuses[0] : DEFAULT_EMPTY_VALUE}
          onChange={onStatusChange}
          placeholder="Выберите статус"
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownIcon />}
        />

        <Input
          style={{ flex: 1 }}
          placeholder="Поиск"
          value={payload.search}
          onChange={(e) => setPayload((prev) => ({ ...prev, search: e.target.value }))}
        />

        <Button className="button-circle primary" icon={<SearchIcon />} onClick={onSearchClick} />
      </div>

      <InfiniteScrollContainer
        loading={fetchLoading || supplyListLoading}
        canLoad={!supplyListLoading && supplyList.length < supplyListCount}
        page={page}
        scrollToTopTrigger={[payload]}
        onPageChange={setPage}
        callback={async (page) => {
          await getSupplyListPart({ ...payload, offset: DEFAULT_LIST_LIMIT * page });
        }}
      >
        <Table
          className={`table-hover supply-list__table ${
            !!supplyList.length && supplyList.length === supplyListCount && 'supply-list__table-end-of-list'
          }`}
          dataSource={supplyList.map((supply) => ({ ...supply, key: supply.id }))}
          columns={renderSupplyListForManagerRecords()}
          onRow={({ id, supplyNeedId }) => ({
            onClick: async () => {
              await getSupplyById({ id, onSuccess: () => setOpenSupplyCard(true) });
              await getNeedById({ id: supplyNeedId });
            },
          })}
          showHeader={false}
          pagination={false}
          locale={{ emptyText: 'Список пуст.' }}
        />
      </InfiniteScrollContainer>

      <SupplyCard onClose={() => setOpenSupplyCard(false)} />

      <NeedCard
        isNested
        onClose={() => {
          setOpenNeedCard(false);
          setOpenSupplyCard(true);
        }}
      />

      <SupplyCorrectionRequestDrawer />
    </>
  );
};

const mapState = (state: RootState) => ({
  subdivisionList: state.supplyListState.subdivisionList,
  userList: state.supplyListState.userList,
  supplyList: state.supplyListState.data,
  supplyListLoading: state.supplyListState.loading,
  supplyListCount: state.supplyListState.count,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getSupplySubdivisionListForManager: dispatch.supplyListState.getSupplySubdivisionListForManager,
  getSupplyUserList: dispatch.supplyListState.getSupplyUserList,
  getSupplyList: dispatch.supplyListState.getSupplyList,
  getSupplyListPart: dispatch.supplyListState.getSupplyListPart,
  getSupplyById: dispatch.supplyState.getSupplyById,
  getNeedById: dispatch.needState.getNeedById,
});

export const SupplyListForManager = connect(mapState, mapDispatch)(SupplyListForManagerComponent);
