import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { ENeedHistoryType, ESupplyCorrectionLabel } from 'common/const/enum';
import { RootState } from 'app/store';
import { ReactComponent as InfoYellowIcon } from 'app/assets/images/redesign/info-yellow.svg';
import { SupplyCardMin } from 'entities/Supply/components/SupplyCardMin';
import { INeedHistorySupplyCorrectionChanges } from 'entities/Need/Need.models';

interface IComponentProps {
  loading: boolean;
  onSupplyClick: (id: number) => void;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const NeedCardSuppliesComponent: FC<AllType> = ({ loading, onSupplyClick, supplyList, historyList, supplyLoading }) => {
  return (
    <div className="need-card-supplies">
      <Spin wrapperClassName="need-card-supplies__spin" spinning={loading || supplyLoading} indicator={<SpinIndicator />}>
        {!supplyList.length ? (
          <div className="text-body color-dark-grey need-card-supplies__empty">Поставки по данной заявке не запланированы.</div>
        ) : (
          supplyList.map((supply) => {
            const supplyHistory = historyList.filter((history) => {
              return (
                (history.changes as INeedHistorySupplyCorrectionChanges).supplyId &&
                (history.changes as INeedHistorySupplyCorrectionChanges).supplyId === supply.id
              );
            });
            const lastHistoryItem = supplyHistory[0];
            const supplyOnCorrection = lastHistoryItem?.type === ENeedHistoryType.CreateSupplyCorrection;

            return (
              <div key={supply.id}>
                <SupplyCardMin supply={supply} onSupplyClick={onSupplyClick} />

                {supplyOnCorrection && (
                  <div className="need-card-supplies__change-badge">
                    <InfoYellowIcon />

                    <div>
                      <span className="text-h4 color-white need-card-supplies__change-badge-title">
                        Запрошена корректировка поставки
                      </span>

                      <span className="text-tag color-white">{`Причина: «${
                        ESupplyCorrectionLabel[(lastHistoryItem?.changes as INeedHistorySupplyCorrectionChanges).reason]
                      }».`}</span>

                      <span className="text-tag color-white">{` Комментарий: «${
                        (lastHistoryItem?.changes as INeedHistorySupplyCorrectionChanges).comment
                      }»`}</span>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </Spin>
    </div>
  );
};

const mapState = (state: RootState) => ({
  supplyList: state.needState.supplyList,
  historyList: state.needState.historyList,
  supplyLoading: state.supplyState.loading,
});

export const NeedCardSupplies = connect(mapState)(NeedCardSuppliesComponent);
